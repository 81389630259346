<template>
  <b-container>
    <div class="activities">
      <h2 class="pt-5 px-lg-5">{{ t("activites").latestactivities }}</h2>
      <mainSectionActivities :isRun="isRunActiv" :id="id" />
      <div class="activity-desc-container px-lg-5 pt-1">
        <div class="d-flex justify-content-between py-2 activity-desc-title">
          <strong v-if="selectedActivity">{{ selectedActivity.title }}</strong>
          <strong v-if="selectedActivity"
            >{{ t("activites").activitydate }}:
            {{
              new Date(selectedActivity.date).toLocaleDateString("en-GB")
            }}</strong
          >
        </div>
        <div class="activity-desc" style="min-height: 300px;">
          <p
            class="lead"
            v-if="selectedActivity"
            v-html="selectedActivity.description"
          ></p>
          <h4 v-else class="text-center">{{ t("activites").NoActivities }}</h4>
        </div>
      </div>
    </div>
  </b-container>
</template>
<script>
  import mainSectionActivities from "@/views/website/pages/activities/components/main-section-activities.vue";
  import { useUtils as useI18nUtils } from "@core/libs/i18n";
  import { mapGetters, mapActions } from 'vuex';
  import { handleLangChange } from "@/libs/event-bus";
  export default {
    components: {
      mainSectionActivities
    },
    computed: {
      ...mapGetters(["websiteActivitiesList"])
    },
    props: {
      id: String
    },
    data: () => ({
      selectedActivity: null,
      isRunActiv: false
    }),
    metaInfo() {
      return {
        titleTemplate: "Al Faisal - " + this.$t("title.activities") + (this.selectedActivity ? ' - ' + this.selectedActivity?.title : ''),
        htmlAttrs: {
          lang: this.$i18n.locale,
        },
        meta: [
          {
            name: "description",
            content: this.selectedActivity ? this.selectedActivity?.description : "description",
          },
          {
            name: "keywords",
            content: "keywords, keywords, keywords, ...",
          },
        ],
      };
    },
    mounted() {
      this.init()
      handleLangChange(this.init)
    },
    methods: {
      async init() {
        this.isRunActiv = false
        await this.getActivitiesForWebsite()
        this.selectedActivity = null
        if (+this.id) {
          this.selectedActivity = await this.websiteActivitiesList.filter(item => {return item.id == this.id || item.linkId == this.id})[0]
        }
        this.isRunActiv = true
      },
      ...mapActions(["getActivitiesForWebsite"]),
    },
    setup() {
      const { t } = useI18nUtils();
      return { t }
    },
    watch: {
      id (id) {
        this.selectedActivity = this.websiteActivitiesList.filter(item => {return item.id == id})[0]
      }
    },
    beforeDestroy() {
        this.$store.dispatch("app/setLoadingWebsite", true);
    }
  }
</script>
<style lang="scss" scoped>
  @import "src/assets/scss/variables/variables.scss";
  .activities {
    h2 {
      color: $primary;
    }
    .activity-desc-container {
      .activity-desc-title {
        color: $primary;
      }
    }
  }
</style>
